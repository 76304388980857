import image from '../../../../../static/lottie/sdk/dapps.png';

const dappsLottie = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 90,
  w: 1080,
  h: 1080,
  nm: 'Feature2',
  ddd: 0,
  assets: [{ id: 'image_0', w: 720, h: 720, u: '', p: image, e: 0 }],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'BTN6 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400.209, 854.02, 0], ix: 2, l: 2 },
        a: { a: 0, k: [208.746, 42.609, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 6,
              s: [0, 0, 100],
            },
            { t: 11, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.67],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.126],
                    [-0.383, 1.342],
                    [-8.768, -5.27],
                    [0.288, -12.169],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.96],
                    [-4.935, -5.413],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [371.173, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.06, -14.23],
                    [-13.56, -14.23],
                    [-13.56, -17.967],
                    [13.56, -17.967],
                    [13.56, -14.23],
                    [2.013, -14.23],
                    [2.013, 17.967],
                    [-2.06, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [349.325, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.924, -17.967],
                    [10.924, -17.967],
                    [10.924, -14.23],
                    [-6.853, -14.23],
                    [-6.853, -2.396],
                    [6.18, -2.396],
                    [6.18, 1.294],
                    [-6.853, 1.294],
                    [-6.853, 17.967],
                    [-10.924, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [321.726, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.254, -17.967],
                    [-9.894, -17.967],
                    [10.325, 11.978],
                    [10.325, -17.967],
                    [14.254, -17.967],
                    [14.254, 17.967],
                    [9.894, 17.967],
                    [-10.373, -12.074],
                    [-10.373, 17.967],
                    [-14.254, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [285.623, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.93],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.299],
                    [7.547, -2.299],
                    [0.263, -8.911],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.145],
                    [0.263, -12.169],
                    [11.57, -1.055],
                    [11.427, 0.863],
                    [-7.643, 0.863],
                    [0.503, 8.816],
                    [7.689, 3.93],
                    [11.331, 4.6],
                    [0.503, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [241.687, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.67],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.126],
                    [-0.383, 1.342],
                    [-8.768, -5.27],
                    [0.288, -12.169],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.96],
                    [-4.935, -5.413],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [216.987, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.348],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.156],
                  ],
                  v: [
                    [-1.917, 8.96],
                    [6.372, -0.24],
                    [6.372, -0.767],
                    [-0.432, 0.911],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.551],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.45, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.092],
                    [-2.492, 12.17],
                    [-9.918, 5.654],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.96],
                    [-6.421, -4.217],
                    [-9.966, -4.887],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.786],
                    [6.516, 11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [192.455, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.804, 0],
                    [-1.102, -4.983],
                    [0, 0],
                    [3.689, 0],
                    [0, -5.126],
                    [-4.647, 0],
                    [-0.718, 3.45],
                    [0, 0],
                    [5.846, 0],
                    [0, 7.092],
                  ],
                  o: [
                    [5.75, 0],
                    [0, 0],
                    [-0.814, -3.354],
                    [-4.6, 0],
                    [0, 5.271],
                    [3.977, 0],
                    [0, 0],
                    [-0.864, 4.935],
                    [-6.852, 0],
                    [0, -6.947],
                  ],
                  v: [
                    [0.43, -12.17],
                    [11.307, -3.881],
                    [7.473, -3.211],
                    [0.43, -8.721],
                    [-7.523, -0.145],
                    [0.43, 8.721],
                    [7.665, 2.923],
                    [11.451, 3.546],
                    [0.43, 12.17],
                    [-11.451, -0.145],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [167.54, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.044, -11.786],
                    [-6.54, 7.283],
                    [-0.312, -5.078],
                    [0.359, -5.078],
                    [6.636, 7.379],
                    [11.092, -11.786],
                    [14.829, -11.786],
                    [9.224, 11.786],
                    [5.15, 11.786],
                    [0.024, 1.581],
                    [-5.15, 11.786],
                    [-9.223, 11.786],
                    [-14.829, -11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [138.145, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.127],
                    [-4.84, 0],
                    [0, 5.174],
                    [4.839, 0],
                  ],
                  o: [
                    [0, 5.174],
                    [4.839, 0],
                    [0, -5.127],
                    [-4.84, 0],
                  ],
                  v: [
                    [-8.241, -0.048],
                    [0, 8.721],
                    [8.24, -0.048],
                    [0, -8.721],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -6.996],
                    [7.043, 0],
                    [0, 7.091],
                    [-7.044, 0],
                  ],
                  o: [
                    [0, 7.091],
                    [-7.044, 0],
                    [0, -6.996],
                    [7.043, 0],
                  ],
                  v: [
                    [12.169, -0.048],
                    [0, 12.169],
                    [-12.169, -0.048],
                    [0, -12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.031, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.401, 0],
                    [0, -6.517],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.593, 0],
                    [0, -5.031],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.581, -2.3],
                    [5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.456],
                    [-3.69, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.301, -17.967],
                    [-6.564, -17.967],
                    [-6.564, -2.156],
                    [1.054, -5.988],
                    [10.301, 3.833],
                    [10.301, 17.967],
                    [6.563, 17.967],
                    [6.563, 4.169],
                    [0.335, -2.492],
                    [-6.564, 4.982],
                    [-6.564, 17.967],
                    [-10.301, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [80.097, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.552, 0],
                    [0, -3.833],
                    [-4.838, -1.102],
                    [0, -7.187],
                    [7.571, 0],
                    [1.246, 6.181],
                    [0, 0],
                    [-5.557, 0],
                    [0, 4.409],
                    [4.791, 1.054],
                    [0, 7.091],
                    [-6.9, 0],
                    [-1.245, -5.798],
                  ],
                  o: [
                    [-0.767, -4.072],
                    [-4.84, 0],
                    [0, 4.265],
                    [5.703, 1.293],
                    [0, 6.564],
                    [-8.193, 0],
                    [0, 0],
                    [0.958, 4.936],
                    [4.744, 0],
                    [0, -4.408],
                    [-5.701, -1.341],
                    [0, -6.085],
                    [6.756, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.104, -7.954],
                    [0.527, -14.71],
                    [-7.714, -8.625],
                    [1.628, -2.348],
                    [13.464, 7.666],
                    [0.671, 18.35],
                    [-13.464, 7.666],
                    [-9.342, 6.611],
                    [0.671, 14.661],
                    [9.294, 7.905],
                    [0.048, 1.389],
                    [-11.882, -8.385],
                    [0.527, -18.35],
                    [13.176, -8.96],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [50.582, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -23.394],
                    [-23.394, 0],
                    [0, 0],
                    [0, 23.394],
                    [23.394, 0],
                    [0, 0],
                    [0, -23.394],
                  ],
                  o: [
                    [0, 23.394],
                    [0, 0],
                    [23.394, 0],
                    [0, -23.394],
                    [0, 0],
                    [-23.394, 0],
                    [0, 0],
                  ],
                  v: [
                    [-208.496, 0],
                    [-166.137, 42.359],
                    [166.137, 42.359],
                    [208.496, 0],
                    [166.137, -42.359],
                    [-166.137, -42.359],
                    [-208.496, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.196078446332, 0.352941176471, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [208.746, 42.609], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.67],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.126],
                    [-0.383, 1.342],
                    [-8.768, -5.27],
                    [0.288, -12.169],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.96],
                    [-4.935, -5.413],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [371.173, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.06, -14.23],
                    [-13.56, -14.23],
                    [-13.56, -17.967],
                    [13.56, -17.967],
                    [13.56, -14.23],
                    [2.013, -14.23],
                    [2.013, 17.967],
                    [-2.06, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [349.325, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.924, -17.967],
                    [10.924, -17.967],
                    [10.924, -14.23],
                    [-6.853, -14.23],
                    [-6.853, -2.396],
                    [6.18, -2.396],
                    [6.18, 1.294],
                    [-6.853, 1.294],
                    [-6.853, 17.967],
                    [-10.924, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [321.726, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.254, -17.967],
                    [-9.894, -17.967],
                    [10.325, 11.978],
                    [10.325, -17.967],
                    [14.254, -17.967],
                    [14.254, 17.967],
                    [9.894, 17.967],
                    [-10.373, -12.074],
                    [-10.373, 17.967],
                    [-14.254, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [285.623, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.93],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.299],
                    [7.547, -2.299],
                    [0.263, -8.911],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.145],
                    [0.263, -12.169],
                    [11.57, -1.055],
                    [11.427, 0.863],
                    [-7.643, 0.863],
                    [0.503, 8.816],
                    [7.689, 3.93],
                    [11.331, 4.6],
                    [0.503, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [241.687, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.67],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.126],
                    [-0.383, 1.342],
                    [-8.768, -5.27],
                    [0.288, -12.169],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.96],
                    [-4.935, -5.413],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [216.987, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.348],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.156],
                  ],
                  v: [
                    [-1.917, 8.96],
                    [6.372, -0.24],
                    [6.372, -0.767],
                    [-0.432, 0.911],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.551],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.45, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.092],
                    [-2.492, 12.17],
                    [-9.918, 5.654],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.96],
                    [-6.421, -4.217],
                    [-9.966, -4.887],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.786],
                    [6.516, 11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [192.455, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.804, 0],
                    [-1.102, -4.983],
                    [0, 0],
                    [3.689, 0],
                    [0, -5.126],
                    [-4.647, 0],
                    [-0.718, 3.45],
                    [0, 0],
                    [5.846, 0],
                    [0, 7.092],
                  ],
                  o: [
                    [5.75, 0],
                    [0, 0],
                    [-0.814, -3.354],
                    [-4.6, 0],
                    [0, 5.271],
                    [3.977, 0],
                    [0, 0],
                    [-0.864, 4.935],
                    [-6.852, 0],
                    [0, -6.947],
                  ],
                  v: [
                    [0.43, -12.17],
                    [11.307, -3.881],
                    [7.473, -3.211],
                    [0.43, -8.721],
                    [-7.523, -0.145],
                    [0.43, 8.721],
                    [7.665, 2.923],
                    [11.451, 3.546],
                    [0.43, 12.17],
                    [-11.451, -0.145],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [167.54, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.044, -11.786],
                    [-6.54, 7.283],
                    [-0.312, -5.078],
                    [0.359, -5.078],
                    [6.636, 7.379],
                    [11.092, -11.786],
                    [14.829, -11.786],
                    [9.224, 11.786],
                    [5.15, 11.786],
                    [0.024, 1.581],
                    [-5.15, 11.786],
                    [-9.223, 11.786],
                    [-14.829, -11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [138.145, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.127],
                    [-4.84, 0],
                    [0, 5.174],
                    [4.839, 0],
                  ],
                  o: [
                    [0, 5.174],
                    [4.839, 0],
                    [0, -5.127],
                    [-4.84, 0],
                  ],
                  v: [
                    [-8.241, -0.048],
                    [0, 8.721],
                    [8.24, -0.048],
                    [0, -8.721],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -6.996],
                    [7.043, 0],
                    [0, 7.091],
                    [-7.044, 0],
                  ],
                  o: [
                    [0, 7.091],
                    [-7.044, 0],
                    [0, -6.996],
                    [7.043, 0],
                  ],
                  v: [
                    [12.169, -0.048],
                    [0, 12.169],
                    [-12.169, -0.048],
                    [0, -12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.031, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.401, 0],
                    [0, -6.517],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.593, 0],
                    [0, -5.031],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.581, -2.3],
                    [5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.456],
                    [-3.69, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.301, -17.967],
                    [-6.564, -17.967],
                    [-6.564, -2.156],
                    [1.054, -5.988],
                    [10.301, 3.833],
                    [10.301, 17.967],
                    [6.563, 17.967],
                    [6.563, 4.169],
                    [0.335, -2.492],
                    [-6.564, 4.982],
                    [-6.564, 17.967],
                    [-10.301, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [80.097, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 24',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.552, 0],
                    [0, -3.833],
                    [-4.838, -1.102],
                    [0, -7.187],
                    [7.571, 0],
                    [1.246, 6.181],
                    [0, 0],
                    [-5.557, 0],
                    [0, 4.409],
                    [4.791, 1.054],
                    [0, 7.091],
                    [-6.9, 0],
                    [-1.245, -5.798],
                  ],
                  o: [
                    [-0.767, -4.072],
                    [-4.84, 0],
                    [0, 4.265],
                    [5.703, 1.293],
                    [0, 6.564],
                    [-8.193, 0],
                    [0, 0],
                    [0.958, 4.936],
                    [4.744, 0],
                    [0, -4.408],
                    [-5.701, -1.341],
                    [0, -6.085],
                    [6.756, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.104, -7.954],
                    [0.527, -14.71],
                    [-7.714, -8.625],
                    [1.628, -2.348],
                    [13.464, 7.666],
                    [0.671, 18.35],
                    [-13.464, 7.666],
                    [-9.342, 6.611],
                    [0.671, 14.661],
                    [9.294, 7.905],
                    [0.048, 1.389],
                    [-11.882, -8.385],
                    [0.527, -18.35],
                    [13.176, -8.96],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [50.582, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 25',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'BTN5 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [893.866, 750.484, 0], ix: 2, l: 2 },
        a: { a: 0, k: [170.389, 42.609, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [0, 0, 100],
            },
            { t: 9, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.48],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.504],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.922],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.895],
                    [8.336, -9.895],
                    [8.336, -6.637],
                    [-0.479, -6.637],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.913],
                    [2.875, 14.062],
                    [-4.264, 7.018],
                    [-4.264, -6.637],
                    [-8.336, -6.637],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [296.474, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.498, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.145],
                    [0.264, -12.17],
                    [11.57, -1.055],
                    [11.428, 0.862],
                    [-7.642, 0.862],
                    [0.503, 8.815],
                    [7.69, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [274.026, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.03],
                    [-5.941, 4.168],
                    [0.479, 8.959],
                    [5.845, 5.126],
                    [-0.383, 1.341],
                    [-8.768, -5.271],
                    [0.288, -12.17],
                    [9.391, -5.894],
                    [5.654, -5.032],
                    [0.288, -8.96],
                    [-4.935, -5.414],
                    [0.863, -1.965],
                    [9.726, 4.886],
                    [0.431, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [249.327, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.03],
                    [-5.941, 4.168],
                    [0.479, 8.959],
                    [5.845, 5.126],
                    [-0.383, 1.341],
                    [-8.768, -5.271],
                    [0.288, -12.17],
                    [9.391, -5.894],
                    [5.654, -5.032],
                    [0.288, -8.96],
                    [-4.935, -5.414],
                    [0.863, -1.965],
                    [9.726, 4.886],
                    [0.431, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [226.999, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.156],
                  ],
                  v: [
                    [-1.917, 8.959],
                    [6.372, -0.24],
                    [6.372, -0.767],
                    [-0.432, 0.91],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.551],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.449, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.17],
                    [-9.918, 5.653],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.96],
                    [-6.421, -4.217],
                    [-9.966, -4.888],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.786],
                    [6.516, 11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [202.468, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [174.103, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.156],
                  ],
                  v: [
                    [-1.917, 8.959],
                    [6.372, -0.24],
                    [6.372, -0.767],
                    [-0.432, 0.91],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.551],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.449, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.17],
                    [-9.918, 5.653],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.96],
                    [-6.421, -4.217],
                    [-9.966, -4.888],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.786],
                    [6.516, 11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [154.89, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.48],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.504],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.922],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.895],
                    [8.336, -9.895],
                    [8.336, -6.637],
                    [-0.479, -6.637],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.913],
                    [2.875, 14.062],
                    [-4.264, 7.018],
                    [-4.264, -6.637],
                    [-8.336, -6.637],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [133.808, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.892, -6.444],
                    [1.845, -6.444],
                    [1.845, 17.129],
                    [-1.892, 17.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.486, 0],
                    [0, -1.533],
                    [1.485, 0],
                    [0, 1.485],
                  ],
                  o: [
                    [1.485, 0],
                    [0, 1.485],
                    [-1.486, 0],
                    [0, -1.533],
                  ],
                  v: [
                    [-0.023, -17.129],
                    [2.611, -14.541],
                    [-0.023, -11.954],
                    [-2.611, -14.541],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [119.266, 40.046], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.887, 0],
                    [0, 5.078],
                    [4.744, 0],
                    [0, -5.079],
                  ],
                  o: [
                    [4.744, 0],
                    [0, -5.079],
                    [-4.887, 0],
                    [0, 5.078],
                  ],
                  v: [
                    [0.119, 3.21],
                    [8.121, -5.365],
                    [0.119, -13.99],
                    [-7.978, -5.365],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.756, 0],
                    [-1.965, -2.634],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [7.044, 0],
                    [0.671, 4.552],
                    [0, 0],
                    [-4.216, 0],
                    [0, 4.886],
                    [0, 0],
                    [3.497, 0],
                    [0, 6.898],
                  ],
                  o: [
                    [3.545, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 6.756],
                    [-6.42, 0],
                    [0, 0],
                    [0.527, 3.162],
                    [4.696, 0],
                    [0, 0],
                    [-1.965, 2.587],
                    [-6.756, 0],
                    [0, -6.996],
                  ],
                  v: [
                    [-0.312, -17.439],
                    [8.169, -13.225],
                    [8.265, -17.058],
                    [11.906, -17.058],
                    [11.906, 6.277],
                    [0.167, 17.439],
                    [-10.948, 9.486],
                    [-7.115, 8.769],
                    [0.167, 13.99],
                    [8.121, 6.516],
                    [8.121, 2.492],
                    [-0.312, 6.66],
                    [-11.906, -5.365],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [97.657, 50.659], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.893, -6.444],
                    [1.844, -6.444],
                    [1.844, 17.129],
                    [-1.893, 17.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.485, 0],
                    [0, -1.533],
                    [1.485, 0],
                    [0, 1.485],
                  ],
                  o: [
                    [1.485, 0],
                    [0, 1.485],
                    [-1.485, 0],
                    [0, -1.533],
                  ],
                  v: [
                    [-0.023, -17.129],
                    [2.612, -14.541],
                    [-0.023, -11.954],
                    [-2.612, -14.541],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [77.917, 40.046], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 8.719],
                    [8.146, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [8.146, 0],
                    [0, -8.673],
                    [0, 0],
                  ],
                  v: [
                    [-10.205, -14.23],
                    [-10.205, 14.23],
                    [-3.641, 14.23],
                    [10.063, -0.047],
                    [-3.641, -14.23],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -10.685],
                    [10.685, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [10.685, 0],
                    [0, 10.732],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.277, -17.967],
                    [-3.641, -17.967],
                    [14.277, -0.047],
                    [-3.641, 17.967],
                    [-14.277, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [54.99, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -23.394],
                    [-23.394, 0],
                    [0, 0],
                    [0, 23.394],
                    [23.395, 0],
                    [0, 0],
                    [0, -23.394],
                  ],
                  o: [
                    [0, 23.394],
                    [0, 0],
                    [23.395, 0],
                    [0, -23.394],
                    [0, 0],
                    [-23.394, 0],
                    [0, 0],
                  ],
                  v: [
                    [-170.139, 0],
                    [-127.78, 42.359],
                    [127.78, 42.359],
                    [170.139, 0],
                    [127.78, -42.359],
                    [-127.78, -42.359],
                    [-170.139, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.458823559331, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [170.39, 42.609], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.48],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.504],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.922],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.895],
                    [8.336, -9.895],
                    [8.336, -6.637],
                    [-0.479, -6.637],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.913],
                    [2.875, 14.062],
                    [-4.264, 7.018],
                    [-4.264, -6.637],
                    [-8.336, -6.637],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [296.474, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.498, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.145],
                    [0.264, -12.17],
                    [11.57, -1.055],
                    [11.428, 0.862],
                    [-7.642, 0.862],
                    [0.503, 8.815],
                    [7.69, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [274.026, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.03],
                    [-5.941, 4.168],
                    [0.479, 8.959],
                    [5.845, 5.126],
                    [-0.383, 1.341],
                    [-8.768, -5.271],
                    [0.288, -12.17],
                    [9.391, -5.894],
                    [5.654, -5.032],
                    [0.288, -8.96],
                    [-4.935, -5.414],
                    [0.863, -1.965],
                    [9.726, 4.886],
                    [0.431, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [249.327, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.695],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.588],
                    [3.258, 0.575],
                    [0, 5.126],
                    [-5.319, 0],
                    [-1.15, -4.024],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.928],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.682],
                    [-3.067, 0],
                    [0, 2.587],
                    [4.072, 0.719],
                    [0, 4.168],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.03],
                    [-5.941, 4.168],
                    [0.479, 8.959],
                    [5.845, 5.126],
                    [-0.383, 1.341],
                    [-8.768, -5.271],
                    [0.288, -12.17],
                    [9.391, -5.894],
                    [5.654, -5.032],
                    [0.288, -8.96],
                    [-4.935, -5.414],
                    [0.863, -1.965],
                    [9.726, 4.886],
                    [0.431, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [226.999, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.156],
                  ],
                  v: [
                    [-1.917, 8.959],
                    [6.372, -0.24],
                    [6.372, -0.767],
                    [-0.432, 0.91],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.551],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.449, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.17],
                    [-9.918, 5.653],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.96],
                    [-6.421, -4.217],
                    [-9.966, -4.888],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.786],
                    [6.516, 11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [202.468, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [174.103, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.156],
                  ],
                  v: [
                    [-1.917, 8.959],
                    [6.372, -0.24],
                    [6.372, -0.767],
                    [-0.432, 0.91],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.551],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.449, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.17],
                    [-9.918, 5.653],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.96],
                    [-6.421, -4.217],
                    [-9.966, -4.888],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.786],
                    [6.516, 11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [154.89, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.48],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.504],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.922],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.895],
                    [8.336, -9.895],
                    [8.336, -6.637],
                    [-0.479, -6.637],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.913],
                    [2.875, 14.062],
                    [-4.264, 7.018],
                    [-4.264, -6.637],
                    [-8.336, -6.637],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [133.808, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.892, -6.444],
                    [1.845, -6.444],
                    [1.845, 17.129],
                    [-1.892, 17.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.486, 0],
                    [0, -1.533],
                    [1.485, 0],
                    [0, 1.485],
                  ],
                  o: [
                    [1.485, 0],
                    [0, 1.485],
                    [-1.486, 0],
                    [0, -1.533],
                  ],
                  v: [
                    [-0.023, -17.129],
                    [2.611, -14.541],
                    [-0.023, -11.954],
                    [-2.611, -14.541],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [119.266, 40.046], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.887, 0],
                    [0, 5.078],
                    [4.744, 0],
                    [0, -5.079],
                  ],
                  o: [
                    [4.744, 0],
                    [0, -5.079],
                    [-4.887, 0],
                    [0, 5.078],
                  ],
                  v: [
                    [0.119, 3.21],
                    [8.121, -5.365],
                    [0.119, -13.99],
                    [-7.978, -5.365],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.756, 0],
                    [-1.965, -2.634],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [7.044, 0],
                    [0.671, 4.552],
                    [0, 0],
                    [-4.216, 0],
                    [0, 4.886],
                    [0, 0],
                    [3.497, 0],
                    [0, 6.898],
                  ],
                  o: [
                    [3.545, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 6.756],
                    [-6.42, 0],
                    [0, 0],
                    [0.527, 3.162],
                    [4.696, 0],
                    [0, 0],
                    [-1.965, 2.587],
                    [-6.756, 0],
                    [0, -6.996],
                  ],
                  v: [
                    [-0.312, -17.439],
                    [8.169, -13.225],
                    [8.265, -17.058],
                    [11.906, -17.058],
                    [11.906, 6.277],
                    [0.167, 17.439],
                    [-10.948, 9.486],
                    [-7.115, 8.769],
                    [0.167, 13.99],
                    [8.121, 6.516],
                    [8.121, 2.492],
                    [-0.312, 6.66],
                    [-11.906, -5.365],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [97.657, 50.659], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.893, -6.444],
                    [1.844, -6.444],
                    [1.844, 17.129],
                    [-1.893, 17.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.485, 0],
                    [0, -1.533],
                    [1.485, 0],
                    [0, 1.485],
                  ],
                  o: [
                    [1.485, 0],
                    [0, 1.485],
                    [-1.485, 0],
                    [0, -1.533],
                  ],
                  v: [
                    [-0.023, -17.129],
                    [2.612, -14.541],
                    [-0.023, -11.954],
                    [-2.612, -14.541],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [77.917, 40.046], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 24',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 8.719],
                    [8.146, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [8.146, 0],
                    [0, -8.673],
                    [0, 0],
                  ],
                  v: [
                    [-10.205, -14.23],
                    [-10.205, 14.23],
                    [-3.641, 14.23],
                    [10.063, -0.047],
                    [-3.641, -14.23],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -10.685],
                    [10.685, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [10.685, 0],
                    [0, 10.732],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.277, -17.967],
                    [-3.641, -17.967],
                    [14.277, -0.047],
                    [-3.641, 17.967],
                    [-14.277, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [54.99, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 25',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'BTN4 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [161.461, 595.75, 0], ix: 2, l: 2 },
        a: { a: 0, k: [128.298, 42.609, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 12,
              s: [0, 0, 100],
            },
            { t: 15, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.48],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.504],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.922],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.342, -14.062],
                    [-0.48, -14.062],
                    [-0.48, -9.895],
                    [8.336, -9.895],
                    [8.336, -6.637],
                    [-0.48, -6.637],
                    [-0.48, 6.397],
                    [3.545, 10.612],
                    [7.138, 9.798],
                    [8.096, 12.913],
                    [2.874, 14.062],
                    [-4.265, 7.018],
                    [-4.265, -6.637],
                    [-8.337, -6.637],
                    [-8.337, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [212.291, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.306, 0],
                    [-0.862, -0.239],
                    [0, 0],
                    [0.766, 0],
                    [0, -5.271],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.342, -2.491],
                    [0.814, 0],
                    [0, 0],
                    [-0.862, -0.24],
                    [-3.546, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.635, -11.595],
                    [-2.947, -11.595],
                    [-2.85, -7.858],
                    [4.288, -11.978],
                    [6.635, -11.643],
                    [6.062, -7.953],
                    [3.667, -8.288],
                    [-2.898, -0.287],
                    [-2.898, 11.978],
                    [-6.635, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [195.496, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.127],
                    [-4.84, 0],
                    [0, 5.174],
                    [4.839, 0],
                  ],
                  o: [
                    [0, 5.174],
                    [4.839, 0],
                    [0, -5.127],
                    [-4.84, 0],
                  ],
                  v: [
                    [-8.24, -0.048],
                    [0.001, 8.72],
                    [8.241, -0.048],
                    [0.001, -8.721],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -6.996],
                    [7.043, 0],
                    [0, 7.091],
                    [-7.044, 0],
                  ],
                  o: [
                    [0, 7.091],
                    [-7.044, 0],
                    [0, -6.996],
                    [7.043, 0],
                  ],
                  v: [
                    [12.17, -0.048],
                    [0.001, 12.17],
                    [-12.169, -0.048],
                    [0.001, -12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [170.701, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.127],
                    [-4.744, 0],
                    [0, 5.174],
                    [4.695, 0],
                  ],
                  o: [
                    [0, 5.174],
                    [4.695, 0],
                    [0, -5.127],
                    [-4.744, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.095, 3.642],
                    [7.954, -5.126],
                    [-0.095, -13.799],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.996],
                    [6.517, 0],
                    [1.965, 2.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.965, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.593, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.866],
                    [-8.193, -12.792],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.091],
                    [-8.145, 2.73],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [142.528, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.571, -0.145],
                    [0.264, -12.17],
                    [11.571, -1.055],
                    [11.428, 0.862],
                    [-7.643, 0.862],
                    [0.503, 8.815],
                    [7.69, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [113.037, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [93.608, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.145],
                    [0.264, -12.17],
                    [11.571, -1.055],
                    [11.428, 0.862],
                    [-7.643, 0.862],
                    [0.503, 8.815],
                    [7.689, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [74.132, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.061, -14.23],
                    [-13.56, -14.23],
                    [-13.56, -17.967],
                    [13.56, -17.967],
                    [13.56, -14.23],
                    [2.012, -14.23],
                    [2.012, 17.967],
                    [-2.061, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [49.768, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -23.394],
                    [-23.394, 0],
                    [0, 0],
                    [0, 23.394],
                    [23.394, 0],
                    [0, 0],
                    [0, -23.394],
                  ],
                  o: [
                    [0, 23.394],
                    [0, 0],
                    [23.394, 0],
                    [0, -23.394],
                    [0, 0],
                    [-23.394, 0],
                    [0, 0],
                  ],
                  v: [
                    [-128.048, 0],
                    [-85.689, 42.359],
                    [85.689, 42.359],
                    [128.048, 0],
                    [85.689, -42.359],
                    [-85.689, -42.359],
                    [-128.048, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.078431372549, 0.662745098039, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [128.298, 42.609], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.48],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.504],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.922],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.342, -14.062],
                    [-0.48, -14.062],
                    [-0.48, -9.895],
                    [8.336, -9.895],
                    [8.336, -6.637],
                    [-0.48, -6.637],
                    [-0.48, 6.397],
                    [3.545, 10.612],
                    [7.138, 9.798],
                    [8.096, 12.913],
                    [2.874, 14.062],
                    [-4.265, 7.018],
                    [-4.265, -6.637],
                    [-8.337, -6.637],
                    [-8.337, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [212.291, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.306, 0],
                    [-0.862, -0.239],
                    [0, 0],
                    [0.766, 0],
                    [0, -5.271],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.342, -2.491],
                    [0.814, 0],
                    [0, 0],
                    [-0.862, -0.24],
                    [-3.546, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.635, -11.595],
                    [-2.947, -11.595],
                    [-2.85, -7.858],
                    [4.288, -11.978],
                    [6.635, -11.643],
                    [6.062, -7.953],
                    [3.667, -8.288],
                    [-2.898, -0.287],
                    [-2.898, 11.978],
                    [-6.635, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [195.496, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.127],
                    [-4.84, 0],
                    [0, 5.174],
                    [4.839, 0],
                  ],
                  o: [
                    [0, 5.174],
                    [4.839, 0],
                    [0, -5.127],
                    [-4.84, 0],
                  ],
                  v: [
                    [-8.24, -0.048],
                    [0.001, 8.72],
                    [8.241, -0.048],
                    [0.001, -8.721],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -6.996],
                    [7.043, 0],
                    [0, 7.091],
                    [-7.044, 0],
                  ],
                  o: [
                    [0, 7.091],
                    [-7.044, 0],
                    [0, -6.996],
                    [7.043, 0],
                  ],
                  v: [
                    [12.17, -0.048],
                    [0.001, 12.17],
                    [-12.169, -0.048],
                    [0.001, -12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [170.701, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.127],
                    [-4.744, 0],
                    [0, 5.174],
                    [4.695, 0],
                  ],
                  o: [
                    [0, 5.174],
                    [4.695, 0],
                    [0, -5.127],
                    [-4.744, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.095, 3.642],
                    [7.954, -5.126],
                    [-0.095, -13.799],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.996],
                    [6.517, 0],
                    [1.965, 2.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.965, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.593, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.866],
                    [-8.193, -12.792],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.091],
                    [-8.145, 2.73],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [142.528, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.571, -0.145],
                    [0.264, -12.17],
                    [11.571, -1.055],
                    [11.428, 0.862],
                    [-7.643, 0.862],
                    [0.503, 8.815],
                    [7.69, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [113.037, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [93.608, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.217],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.14],
                    [-6.852, 0],
                    [0, -6.563],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.983],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.145],
                    [0.264, -12.17],
                    [11.571, -1.055],
                    [11.428, 0.862],
                    [-7.643, 0.862],
                    [0.503, 8.815],
                    [7.689, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [74.132, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.061, -14.23],
                    [-13.56, -14.23],
                    [-13.56, -17.967],
                    [13.56, -17.967],
                    [13.56, -14.23],
                    [2.012, -14.23],
                    [2.012, 17.967],
                    [-2.061, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [49.768, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'BTN3 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [910.122, 528.928, 0], ix: 2, l: 2 },
        a: { a: 0, k: [93.992, 42.609, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 8,
              s: [0, 0, 100],
            },
            { t: 12, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.744, 0],
                    [0, 5.175],
                    [4.695, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.695, 0],
                    [0, -5.126],
                    [-4.744, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.095, 3.642],
                    [7.954, -5.126],
                    [-0.095, -13.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.995],
                    [6.517, 0],
                    [1.965, 2.73],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.965, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.593, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.866],
                    [-8.193, -12.793],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.09],
                    [-8.145, 2.731],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [138.792, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.157],
                  ],
                  v: [
                    [-1.917, 8.96],
                    [6.372, -0.239],
                    [6.372, -0.767],
                    [-0.432, 0.911],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.024],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.846],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.552],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.449, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.169],
                    [-9.918, 5.654],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.959],
                    [-6.421, -4.216],
                    [-9.966, -4.887],
                    [0.287, -12.169],
                    [9.966, -2.97],
                    [9.966, 11.787],
                    [6.516, 11.787],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [109.564, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.044, -11.786],
                    [-6.54, 7.282],
                    [-0.312, -5.078],
                    [0.359, -5.078],
                    [6.636, 7.378],
                    [11.092, -11.786],
                    [14.829, -11.786],
                    [9.224, 11.786],
                    [5.15, 11.786],
                    [0.024, 1.581],
                    [-5.15, 11.786],
                    [-9.223, 11.786],
                    [-14.829, -11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [81.654, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.552, 0],
                    [0, -3.833],
                    [-4.838, -1.102],
                    [0, -7.187],
                    [7.571, 0],
                    [1.246, 6.18],
                    [0, 0],
                    [-5.557, 0],
                    [0, 4.408],
                    [4.791, 1.054],
                    [0, 7.091],
                    [-6.9, 0],
                    [-1.245, -5.797],
                  ],
                  o: [
                    [-0.767, -4.072],
                    [-4.84, 0],
                    [0, 4.264],
                    [5.703, 1.293],
                    [0, 6.564],
                    [-8.193, 0],
                    [0, 0],
                    [0.958, 4.935],
                    [4.744, 0],
                    [0, -4.408],
                    [-5.701, -1.342],
                    [0, -6.085],
                    [6.755, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.104, -7.954],
                    [0.528, -14.709],
                    [-7.714, -8.624],
                    [1.629, -2.348],
                    [13.464, 7.666],
                    [0.671, 18.35],
                    [-13.464, 7.666],
                    [-9.342, 6.611],
                    [0.671, 14.661],
                    [9.294, 7.905],
                    [0.047, 1.389],
                    [-11.881, -8.385],
                    [0.528, -18.35],
                    [13.176, -8.96],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [50.582, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -23.394],
                    [-23.394, 0],
                    [0, 0],
                    [0, 23.394],
                    [23.395, 0],
                    [0, 0],
                    [0, -23.394],
                  ],
                  o: [
                    [0, 23.394],
                    [0, 0],
                    [23.395, 0],
                    [0, -23.394],
                    [0, 0],
                    [-23.394, 0],
                    [0, 0],
                  ],
                  v: [
                    [-93.742, 0],
                    [-51.383, 42.359],
                    [51.383, 42.359],
                    [93.742, 0],
                    [51.383, -42.359],
                    [-51.383, -42.359],
                    [-93.742, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.078431372549, 0.662745098039, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [93.992, 42.609], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.744, 0],
                    [0, 5.175],
                    [4.695, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.695, 0],
                    [0, -5.126],
                    [-4.744, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.095, 3.642],
                    [7.954, -5.126],
                    [-0.095, -13.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.995],
                    [6.517, 0],
                    [1.965, 2.73],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.965, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.593, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.866],
                    [-8.193, -12.793],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.09],
                    [-8.145, 2.731],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [138.792, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.157],
                  ],
                  v: [
                    [-1.917, 8.96],
                    [6.372, -0.239],
                    [6.372, -0.767],
                    [-0.432, 0.911],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.024],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.846],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.552],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.449, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.169],
                    [-9.918, 5.654],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.959],
                    [-6.421, -4.216],
                    [-9.966, -4.887],
                    [0.287, -12.169],
                    [9.966, -2.97],
                    [9.966, 11.787],
                    [6.516, 11.787],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [109.564, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.044, -11.786],
                    [-6.54, 7.282],
                    [-0.312, -5.078],
                    [0.359, -5.078],
                    [6.636, 7.378],
                    [11.092, -11.786],
                    [14.829, -11.786],
                    [9.224, 11.786],
                    [5.15, 11.786],
                    [0.024, 1.581],
                    [-5.15, 11.786],
                    [-9.223, 11.786],
                    [-14.829, -11.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [81.654, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.552, 0],
                    [0, -3.833],
                    [-4.838, -1.102],
                    [0, -7.187],
                    [7.571, 0],
                    [1.246, 6.18],
                    [0, 0],
                    [-5.557, 0],
                    [0, 4.408],
                    [4.791, 1.054],
                    [0, 7.091],
                    [-6.9, 0],
                    [-1.245, -5.797],
                  ],
                  o: [
                    [-0.767, -4.072],
                    [-4.84, 0],
                    [0, 4.264],
                    [5.703, 1.293],
                    [0, 6.564],
                    [-8.193, 0],
                    [0, 0],
                    [0.958, 4.935],
                    [4.744, 0],
                    [0, -4.408],
                    [-5.701, -1.342],
                    [0, -6.085],
                    [6.755, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.104, -7.954],
                    [0.528, -14.709],
                    [-7.714, -8.624],
                    [1.629, -2.348],
                    [13.464, 7.666],
                    [0.671, 18.35],
                    [-13.464, 7.666],
                    [-9.342, 6.611],
                    [0.671, 14.661],
                    [9.294, 7.905],
                    [0.047, 1.389],
                    [-11.881, -8.385],
                    [0.528, -18.35],
                    [13.176, -8.96],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [50.582, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'BTN2 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [227.781, 340.897, 0], ix: 2, l: 2 },
        a: { a: 0, k: [140.851, 42.609, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 14,
              s: [0, 0, 100],
            },
            { t: 19, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [240.655, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [229.06, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.216],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.3],
                    [7.547, -2.3],
                    [0.263, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.139],
                    [-6.852, 0],
                    [0, -6.564],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.982],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.144],
                    [0.263, -12.17],
                    [11.57, -1.054],
                    [11.427, 0.863],
                    [-7.643, 0.863],
                    [0.503, 8.816],
                    [7.689, 3.929],
                    [11.331, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [209.583, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.696],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.587],
                    [3.258, 0.575],
                    [0, 5.127],
                    [-5.319, 0],
                    [-1.15, -4.025],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.929],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.683],
                    [-3.067, 0],
                    [0, 2.588],
                    [4.072, 0.719],
                    [0, 4.169],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.127],
                    [-0.383, 1.341],
                    [-8.768, -5.271],
                    [0.288, -12.169],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.959],
                    [-4.935, -5.414],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [184.884, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.731, -3.21],
                    [0, 3.162],
                    [3.017, 0],
                    [0, -2.875],
                  ],
                  o: [
                    [3.785, -1.677],
                    [0, -2.875],
                    [-2.971, 0],
                    [0, 2.204],
                  ],
                  v: [
                    [-3.952, -3.354],
                    [2.277, -10.157],
                    [-2.562, -14.853],
                    [-7.594, -10.253],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.36, 0],
                    [-2.061, 1.917],
                    [0, 0],
                    [0, -2.539],
                  ],
                  o: [
                    [3.018, 0],
                    [0, 0],
                    [-4.839, 2.156],
                    [0, 4.073],
                  ],
                  v: [
                    [-2.946, 14.757],
                    [4.72, 11.882],
                    [-5.294, 0.767],
                    [-10.899, 8.097],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.12, -1.965],
                    [0, 0],
                    [-0.334, 3.162],
                    [0, 0],
                    [1.821, -2.635],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.977, 0],
                    [0, 6.18],
                    [-5.798, 2.923],
                    [0, 2.923],
                    [-5.079, 0],
                    [0, -4.887],
                  ],
                  o: [
                    [0, 0],
                    [1.198, -2.107],
                    [0, 0],
                    [-0.527, 3.977],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.78, 2.587],
                    [-6.756, 0],
                    [0, -3.833],
                    [-2.682, -3.257],
                    [0, -4.886],
                    [5.174, 0],
                    [0, 4.408],
                  ],
                  v: [
                    [-1.652, -0.814],
                    [6.972, 8.959],
                    [9.319, 1.054],
                    [12.961, 1.725],
                    [9.367, 11.691],
                    [14.925, 17.967],
                    [10.182, 17.967],
                    [7.02, 14.422],
                    [-3.329, 18.351],
                    [-14.925, 8.385],
                    [-7.546, -1.869],
                    [-11.522, -10.206],
                    [-2.515, -18.351],
                    [6.157, -10.253],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.344, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-7.594, -17.057],
                    [0.552, 1.533],
                    [7.834, -17.057],
                    [11.81, -17.057],
                    [-1.941, 17.057],
                    [-5.869, 17.057],
                    [-1.557, 6.325],
                    [-11.81, -17.057],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [107.001, 50.659], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-3.738, 0],
                    [0, 4.982],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.305, 0],
                    [0, 6.038],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 4.073],
                    [3.354, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.629, 2.492],
                    [-5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.349, 2.156],
                    [-0.168, 8.529],
                    [6.348, 1.486],
                    [6.348, -11.978],
                    [10.086, -11.978],
                    [10.086, 11.595],
                    [6.635, 11.595],
                    [6.54, 8.193],
                    [-1.078, 11.978],
                    [-10.086, 2.491],
                    [-10.086, -11.978],
                    [-6.349, -11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [80.792, 45.58], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 4.024],
                    [4.312, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [4.312, 0],
                    [0, -4.121],
                    [0, 0],
                  ],
                  v: [
                    [-7.834, 1.197],
                    [-7.834, 14.421],
                    [0.743, 14.421],
                    [7.738, 7.953],
                    [0.695, 1.197],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 3.689],
                    [3.882, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [3.785, 0],
                    [0, -3.593],
                    [0, 0],
                  ],
                  v: [
                    [-7.834, -14.422],
                    [-7.834, -2.396],
                    [-0.023, -2.396],
                    [6.157, -8.529],
                    [-0.168, -14.422],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -5.606],
                    [3.21, -1.342],
                    [0, -4.504],
                    [6.708, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [6.133, 0],
                    [0, 3.928],
                    [4.456, 1.246],
                    [0, 6.181],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.906, -17.967],
                    [0.12, -17.967],
                    [10.229, -8.912],
                    [5.055, -0.959],
                    [11.906, 7.953],
                    [0.935, 17.967],
                    [-11.906, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [52.618, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -23.394],
                    [-23.394, 0],
                    [0, 0],
                    [0, 23.394],
                    [23.394, 0],
                    [0, 0],
                    [0, -23.394],
                  ],
                  o: [
                    [0, 23.394],
                    [0, 0],
                    [23.394, 0],
                    [0, -23.394],
                    [0, 0],
                    [-23.394, 0],
                    [0, 0],
                  ],
                  v: [
                    [-140.601, 0],
                    [-98.242, 42.359],
                    [98.242, 42.359],
                    [140.601, 0],
                    [98.242, -42.359],
                    [-98.242, -42.359],
                    [-140.601, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.458823559331, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [140.851, 42.609], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [240.655, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.868, -17.967],
                    [1.868, -17.967],
                    [1.868, 17.967],
                    [-1.868, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [229.06, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.216],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.499, -2.3],
                    [7.547, -2.3],
                    [0.263, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.139],
                    [-6.852, 0],
                    [0, -6.564],
                    [0.048, -0.623],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.982],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.144],
                    [0.263, -12.17],
                    [11.57, -1.054],
                    [11.427, 0.863],
                    [-7.643, 0.863],
                    [0.503, 8.816],
                    [7.689, 3.929],
                    [11.331, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [209.583, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.696],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.587],
                    [3.258, 0.575],
                    [0, 5.127],
                    [-5.319, 0],
                    [-1.15, -4.025],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.929],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.683],
                    [-3.067, 0],
                    [0, 2.588],
                    [4.072, 0.719],
                    [0, 4.169],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.127],
                    [-0.383, 1.341],
                    [-8.768, -5.271],
                    [0.288, -12.169],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.959],
                    [-4.935, -5.414],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [184.884, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.731, -3.21],
                    [0, 3.162],
                    [3.017, 0],
                    [0, -2.875],
                  ],
                  o: [
                    [3.785, -1.677],
                    [0, -2.875],
                    [-2.971, 0],
                    [0, 2.204],
                  ],
                  v: [
                    [-3.952, -3.354],
                    [2.277, -10.157],
                    [-2.562, -14.853],
                    [-7.594, -10.253],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.36, 0],
                    [-2.061, 1.917],
                    [0, 0],
                    [0, -2.539],
                  ],
                  o: [
                    [3.018, 0],
                    [0, 0],
                    [-4.839, 2.156],
                    [0, 4.073],
                  ],
                  v: [
                    [-2.946, 14.757],
                    [4.72, 11.882],
                    [-5.294, 0.767],
                    [-10.899, 8.097],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.12, -1.965],
                    [0, 0],
                    [-0.334, 3.162],
                    [0, 0],
                    [1.821, -2.635],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.977, 0],
                    [0, 6.18],
                    [-5.798, 2.923],
                    [0, 2.923],
                    [-5.079, 0],
                    [0, -4.887],
                  ],
                  o: [
                    [0, 0],
                    [1.198, -2.107],
                    [0, 0],
                    [-0.527, 3.977],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.78, 2.587],
                    [-6.756, 0],
                    [0, -3.833],
                    [-2.682, -3.257],
                    [0, -4.886],
                    [5.174, 0],
                    [0, 4.408],
                  ],
                  v: [
                    [-1.652, -0.814],
                    [6.972, 8.959],
                    [9.319, 1.054],
                    [12.961, 1.725],
                    [9.367, 11.691],
                    [14.925, 17.967],
                    [10.182, 17.967],
                    [7.02, 14.422],
                    [-3.329, 18.351],
                    [-14.925, 8.385],
                    [-7.546, -1.869],
                    [-11.522, -10.206],
                    [-2.515, -18.351],
                    [6.157, -10.253],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.344, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-7.594, -17.057],
                    [0.552, 1.533],
                    [7.834, -17.057],
                    [11.81, -17.057],
                    [-1.941, 17.057],
                    [-5.869, 17.057],
                    [-1.557, 6.325],
                    [-11.81, -17.057],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [107.001, 50.659], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-3.738, 0],
                    [0, 4.982],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.305, 0],
                    [0, 6.038],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 4.073],
                    [3.354, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.629, 2.492],
                    [-5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.349, 2.156],
                    [-0.168, 8.529],
                    [6.348, 1.486],
                    [6.348, -11.978],
                    [10.086, -11.978],
                    [10.086, 11.595],
                    [6.635, 11.595],
                    [6.54, 8.193],
                    [-1.078, 11.978],
                    [-10.086, 2.491],
                    [-10.086, -11.978],
                    [-6.349, -11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [80.792, 45.58], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 4.024],
                    [4.312, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [4.312, 0],
                    [0, -4.121],
                    [0, 0],
                  ],
                  v: [
                    [-7.834, 1.197],
                    [-7.834, 14.421],
                    [0.743, 14.421],
                    [7.738, 7.953],
                    [0.695, 1.197],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 3.689],
                    [3.882, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [3.785, 0],
                    [0, -3.593],
                    [0, 0],
                  ],
                  v: [
                    [-7.834, -14.422],
                    [-7.834, -2.396],
                    [-0.023, -2.396],
                    [6.157, -8.529],
                    [-0.168, -14.422],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -5.606],
                    [3.21, -1.342],
                    [0, -4.504],
                    [6.708, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [6.133, 0],
                    [0, 3.928],
                    [4.456, 1.246],
                    [0, 6.181],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.906, -17.967],
                    [0.12, -17.967],
                    [10.229, -8.912],
                    [5.055, -0.959],
                    [11.906, 7.953],
                    [0.935, 17.967],
                    [-11.906, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [52.618, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'BTN1 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [763.975, 237.136, 0], ix: 2, l: 2 },
        a: { a: 0, k: [234.57, 42.609, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [0, 0, 100],
            },
            { t: 9, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.353, 0],
                    [0, -6.516],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.593, 0],
                    [0, -5.03],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.629, -2.396],
                    [5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.456],
                    [-3.69, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.302, -11.595],
                    [-6.708, -11.595],
                    [-6.612, -8.05],
                    [1.053, -11.978],
                    [10.302, -2.157],
                    [10.302, 11.978],
                    [6.563, 11.978],
                    [6.563, -1.821],
                    [0.336, -8.48],
                    [-6.565, -1.007],
                    [-6.565, 11.978],
                    [-10.302, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [420.187, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.84, 0],
                    [0, 5.175],
                    [4.839, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.839, 0],
                    [0, -5.126],
                    [-4.84, 0],
                  ],
                  v: [
                    [-8.24, -0.048],
                    [0.001, 8.72],
                    [8.241, -0.048],
                    [0.001, -8.72],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -6.995],
                    [7.043, 0],
                    [0, 7.091],
                    [-7.044, 0],
                  ],
                  o: [
                    [0, 7.091],
                    [-7.044, 0],
                    [0, -6.995],
                    [7.043, 0],
                  ],
                  v: [
                    [12.169, -0.048],
                    [0.001, 12.17],
                    [-12.169, -0.048],
                    [0.001, -12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [391.726, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.893, -6.444],
                    [1.844, -6.444],
                    [1.844, 17.129],
                    [-1.893, 17.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.486, 0],
                    [0, -1.533],
                    [1.484, 0],
                    [0, 1.485],
                  ],
                  o: [
                    [1.484, 0],
                    [0, 1.485],
                    [-1.486, 0],
                    [0, -1.533],
                  ],
                  v: [
                    [-0.023, -17.129],
                    [2.611, -14.542],
                    [-0.023, -11.954],
                    [-2.611, -14.542],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [371.723, 40.047], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.479],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.503],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.923],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.894],
                    [8.336, -9.894],
                    [8.336, -6.636],
                    [-0.479, -6.636],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.912],
                    [2.875, 14.062],
                    [-4.264, 7.019],
                    [-4.264, -6.636],
                    [-8.336, -6.636],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [356.846, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.804, 0],
                    [-1.102, -4.983],
                    [0, 0],
                    [3.689, 0],
                    [0, -5.127],
                    [-4.647, 0],
                    [-0.718, 3.449],
                    [0, 0],
                    [5.846, 0],
                    [0, 7.091],
                  ],
                  o: [
                    [5.75, 0],
                    [0, 0],
                    [-0.814, -3.354],
                    [-4.6, 0],
                    [0, 5.271],
                    [3.977, 0],
                    [0, 0],
                    [-0.864, 4.934],
                    [-6.852, 0],
                    [0, -6.947],
                  ],
                  v: [
                    [0.431, -12.17],
                    [11.306, -3.881],
                    [7.474, -3.21],
                    [0.431, -8.72],
                    [-7.522, -0.144],
                    [0.431, 8.72],
                    [7.665, 2.923],
                    [11.452, 3.546],
                    [0.431, 12.17],
                    [-11.452, -0.144],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [335.189, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.157],
                  ],
                  v: [
                    [-1.917, 8.96],
                    [6.372, -0.239],
                    [6.372, -0.767],
                    [-0.432, 0.911],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.552],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.45, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.17],
                    [-9.918, 5.654],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.959],
                    [-6.421, -4.216],
                    [-9.966, -4.887],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.787],
                    [6.516, 11.787],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [308.262, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.306, 0],
                    [-0.862, -0.24],
                    [0, 0],
                    [0.766, 0],
                    [0, -5.27],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.342, -2.491],
                    [0.814, 0],
                    [0, 0],
                    [-0.862, -0.24],
                    [-3.546, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.635, -11.595],
                    [-2.946, -11.595],
                    [-2.851, -7.858],
                    [4.289, -11.978],
                    [6.635, -11.642],
                    [6.062, -7.954],
                    [3.667, -8.288],
                    [-2.898, -0.288],
                    [-2.898, 11.978],
                    [-6.635, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [289.262, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.216],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.498, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.139],
                    [-6.852, 0],
                    [0, -6.564],
                    [0.048, -0.622],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.984],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.144],
                    [0.264, -12.17],
                    [11.57, -1.054],
                    [11.428, 0.862],
                    [-7.642, 0.862],
                    [0.503, 8.816],
                    [7.69, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [265.019, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.479],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.503],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.923],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.894],
                    [8.336, -9.894],
                    [8.336, -6.636],
                    [-0.479, -6.636],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.912],
                    [2.875, 14.062],
                    [-4.264, 7.019],
                    [-4.264, -6.636],
                    [-8.336, -6.636],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [242.475, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.353, 0],
                    [0, -6.516],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.593, 0],
                    [0, -5.03],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.629, -2.396],
                    [5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.456],
                    [-3.69, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.302, -11.595],
                    [-6.708, -11.595],
                    [-6.612, -8.05],
                    [1.053, -11.978],
                    [10.302, -2.157],
                    [10.302, 11.978],
                    [6.563, 11.978],
                    [6.563, -1.821],
                    [0.336, -8.48],
                    [-6.565, -1.007],
                    [-6.565, 11.978],
                    [-10.302, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [220.004, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.036, -17.967],
                    [2.036, -17.967],
                    [2.036, 17.967],
                    [-2.036, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [198.275, 39.209], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.696],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.587],
                    [3.258, 0.575],
                    [0, 5.127],
                    [-5.319, 0],
                    [-1.15, -4.025],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.929],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.683],
                    [-3.067, 0],
                    [0, 2.588],
                    [4.072, 0.719],
                    [0, 4.169],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.127],
                    [-0.383, 1.342],
                    [-8.768, -5.27],
                    [0.288, -12.17],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.959],
                    [-4.935, -5.414],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [169.167, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.743, 0],
                    [0, 5.175],
                    [4.696, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.696, 0],
                    [0, -5.126],
                    [-4.743, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.096, 3.642],
                    [7.954, -5.126],
                    [-0.096, -13.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.995],
                    [6.517, 0],
                    [1.965, 2.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.964, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.594, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.864],
                    [-8.193, -12.793],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.092],
                    [-8.145, 2.731],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.864],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [143.965, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.743, 0],
                    [0, 5.175],
                    [4.696, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.696, 0],
                    [0, -5.126],
                    [-4.743, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.096, 3.642],
                    [7.954, -5.126],
                    [-0.096, -13.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.995],
                    [6.517, 0],
                    [1.965, 2.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.964, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.594, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.864],
                    [-8.193, -12.793],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.092],
                    [-8.145, 2.731],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.864],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [114.211, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.63, 1.868],
                    [-0.12, -13.319],
                    [-5.87, 1.868],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.276, -17.967],
                    [2.229, -17.967],
                    [16.026, 17.967],
                    [11.715, 17.967],
                    [7.02, 5.558],
                    [-7.259, 5.558],
                    [-11.954, 17.967],
                    [-16.026, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [81.702, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.695, 0],
                    [0, 5.175],
                    [4.792, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.792, 0],
                    [0, -5.126],
                    [-4.695, 0],
                  ],
                  v: [
                    [-7.953, 5.941],
                    [0.096, 14.709],
                    [8.146, 5.941],
                    [0.096, -2.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [3.545, 0],
                    [0, 7.091],
                    [-6.66, 0],
                    [-1.965, -2.54],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.964, 2.636],
                    [-6.66, 0],
                    [0, -6.995],
                    [3.497, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.289, 17.775],
                    [8.193, 13.942],
                    [-0.287, 18.159],
                    [-11.883, 5.941],
                    [-0.287, -6.181],
                    [8.146, -2.06],
                    [8.146, -18.159],
                    [11.883, -18.159],
                    [11.883, 17.775],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [49.193, 39.4], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -23.394],
                    [-23.394, 0],
                    [0, 0],
                    [0, 23.394],
                    [23.395, 0],
                    [0, 0],
                    [0, -23.394],
                  ],
                  o: [
                    [0, 23.394],
                    [0, 0],
                    [23.395, 0],
                    [0, -23.394],
                    [0, 0],
                    [-23.394, 0],
                    [0, 0],
                  ],
                  v: [
                    [-234.32, 0],
                    [-191.961, 42.359],
                    [191.961, 42.359],
                    [234.32, 0],
                    [191.961, -42.359],
                    [-191.961, -42.359],
                    [-234.32, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.196078446332, 0.352941176471, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [234.57, 42.609], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.353, 0],
                    [0, -6.516],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.593, 0],
                    [0, -5.03],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.629, -2.396],
                    [5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.456],
                    [-3.69, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.302, -11.595],
                    [-6.708, -11.595],
                    [-6.612, -8.05],
                    [1.053, -11.978],
                    [10.302, -2.157],
                    [10.302, 11.978],
                    [6.563, 11.978],
                    [6.563, -1.821],
                    [0.336, -8.48],
                    [-6.565, -1.007],
                    [-6.565, 11.978],
                    [-10.302, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [420.187, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.84, 0],
                    [0, 5.175],
                    [4.839, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.839, 0],
                    [0, -5.126],
                    [-4.84, 0],
                  ],
                  v: [
                    [-8.24, -0.048],
                    [0.001, 8.72],
                    [8.241, -0.048],
                    [0.001, -8.72],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -6.995],
                    [7.043, 0],
                    [0, 7.091],
                    [-7.044, 0],
                  ],
                  o: [
                    [0, 7.091],
                    [-7.044, 0],
                    [0, -6.995],
                    [7.043, 0],
                  ],
                  v: [
                    [12.169, -0.048],
                    [0.001, 12.17],
                    [-12.169, -0.048],
                    [0.001, -12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [391.726, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.893, -6.444],
                    [1.844, -6.444],
                    [1.844, 17.129],
                    [-1.893, 17.129],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.486, 0],
                    [0, -1.533],
                    [1.484, 0],
                    [0, 1.485],
                  ],
                  o: [
                    [1.484, 0],
                    [0, 1.485],
                    [-1.486, 0],
                    [0, -1.533],
                  ],
                  v: [
                    [-0.023, -17.129],
                    [2.611, -14.542],
                    [-0.023, -11.954],
                    [-2.611, -14.542],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [371.723, 40.047], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.479],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.503],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.923],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.894],
                    [8.336, -9.894],
                    [8.336, -6.636],
                    [-0.479, -6.636],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.912],
                    [2.875, 14.062],
                    [-4.264, 7.019],
                    [-4.264, -6.636],
                    [-8.336, -6.636],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [356.846, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.804, 0],
                    [-1.102, -4.983],
                    [0, 0],
                    [3.689, 0],
                    [0, -5.127],
                    [-4.647, 0],
                    [-0.718, 3.449],
                    [0, 0],
                    [5.846, 0],
                    [0, 7.091],
                  ],
                  o: [
                    [5.75, 0],
                    [0, 0],
                    [-0.814, -3.354],
                    [-4.6, 0],
                    [0, 5.271],
                    [3.977, 0],
                    [0, 0],
                    [-0.864, 4.934],
                    [-6.852, 0],
                    [0, -6.947],
                  ],
                  v: [
                    [0.431, -12.17],
                    [11.306, -3.881],
                    [7.474, -3.21],
                    [0.431, -8.72],
                    [-7.522, -0.144],
                    [0.431, 8.72],
                    [7.665, 2.923],
                    [11.452, 3.546],
                    [0.431, 12.17],
                    [-11.452, -0.144],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [335.189, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.587, 0],
                    [0, 5.941],
                    [0, 0],
                    [0, 0],
                    [0, -2.347],
                  ],
                  o: [
                    [4.217, 0],
                    [0, 0],
                    [0, 0],
                    [-3.593, 0.91],
                    [0, 2.157],
                  ],
                  v: [
                    [-1.917, 8.96],
                    [6.372, -0.239],
                    [6.372, -0.767],
                    [-0.432, 0.911],
                    [-5.989, 5.318],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [3.833, 0],
                    [0, 4.025],
                    [-5.653, 1.437],
                    [0, 0],
                    [3.402, 0],
                    [0.862, -3.21],
                    [0, 0],
                    [-5.318, 0],
                    [0, -5.845],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.868, 3.21],
                    [-4.455, 0],
                    [0, -4.552],
                    [0, 0],
                    [-0.384, -3.45],
                    [-3.45, 0],
                    [0, 0],
                    [1.102, -4.551],
                    [5.942, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [6.42, 7.091],
                    [-2.492, 12.17],
                    [-9.918, 5.654],
                    [-0.48, -2.204],
                    [6.324, -3.833],
                    [0.239, -8.959],
                    [-6.421, -4.216],
                    [-9.966, -4.887],
                    [0.287, -12.17],
                    [9.966, -2.971],
                    [9.966, 11.787],
                    [6.516, 11.787],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [308.262, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.306, 0],
                    [-0.862, -0.24],
                    [0, 0],
                    [0.766, 0],
                    [0, -5.27],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.342, -2.491],
                    [0.814, 0],
                    [0, 0],
                    [-0.862, -0.24],
                    [-3.546, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.635, -11.595],
                    [-2.946, -11.595],
                    [-2.851, -7.858],
                    [4.289, -11.978],
                    [6.635, -11.642],
                    [6.062, -7.954],
                    [3.667, -8.288],
                    [-2.898, -0.288],
                    [-2.898, 11.978],
                    [-6.635, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [289.262, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 24',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.767, -4.216],
                    [0, 0],
                    [3.976, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.288, -3.929],
                    [-3.785, 0],
                  ],
                  v: [
                    [-7.498, -2.3],
                    [7.547, -2.3],
                    [0.264, -8.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 7.139],
                    [-6.852, 0],
                    [0, -6.564],
                    [0.048, -0.622],
                    [0, 0],
                    [-4.6, 0],
                    [-0.958, 3.018],
                    [0, 0],
                    [5.559, 0],
                  ],
                  o: [
                    [0, -6.947],
                    [6.612, 0],
                    [0, 0.575],
                    [0, 0],
                    [0.431, 4.984],
                    [3.93, 0],
                    [0, 0],
                    [-1.15, 4.648],
                    [-7.378, 0],
                  ],
                  v: [
                    [-11.57, -0.144],
                    [0.264, -12.17],
                    [11.57, -1.054],
                    [11.428, 0.862],
                    [-7.642, 0.862],
                    [0.503, 8.816],
                    [7.69, 3.929],
                    [11.332, 4.599],
                    [0.503, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [265.019, 45.389], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 25',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.635, 0],
                    [-1.15, 0.479],
                    [0, 0],
                    [1.869, 0],
                    [0, 4.503],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.923],
                    [1.198, 0],
                    [0, 0],
                    [-1.533, 0.718],
                    [-4.504, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.341, -14.062],
                    [-0.479, -14.062],
                    [-0.479, -9.894],
                    [8.336, -9.894],
                    [8.336, -6.636],
                    [-0.479, -6.636],
                    [-0.479, 6.396],
                    [3.546, 10.612],
                    [7.138, 9.797],
                    [8.096, 12.912],
                    [2.875, 14.062],
                    [-4.264, 7.019],
                    [-4.264, -6.636],
                    [-8.336, -6.636],
                    [-8.336, -7.547],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [242.475, 43.496], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 26',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 26,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.353, 0],
                    [0, -6.516],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.593, 0],
                    [0, -5.03],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.629, -2.396],
                    [5.175, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -4.456],
                    [-3.69, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.302, -11.595],
                    [-6.708, -11.595],
                    [-6.612, -8.05],
                    [1.053, -11.978],
                    [10.302, -2.157],
                    [10.302, 11.978],
                    [6.563, 11.978],
                    [6.563, -1.821],
                    [0.336, -8.48],
                    [-6.565, -1.007],
                    [-6.565, 11.978],
                    [-10.302, 11.978],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [220.004, 45.197], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 27',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 27,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.036, -17.967],
                    [2.036, -17.967],
                    [2.036, 17.967],
                    [-2.036, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [198.275, 39.209], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 28',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 28,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.198, 4.696],
                    [0, 0],
                    [-3.641, 0],
                    [0, 2.587],
                    [3.258, 0.575],
                    [0, 5.127],
                    [-5.319, 0],
                    [-1.15, -4.025],
                    [0, 0],
                    [2.875, 0],
                    [0, -2.204],
                    [-3.114, -0.575],
                    [0, -5.414],
                    [6.085, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.767, 3.114],
                    [3.066, 0],
                    [0, -2.731],
                    [-3.977, -0.671],
                    [0, -3.929],
                    [4.743, 0],
                    [0, 0],
                    [-0.719, -2.683],
                    [-3.067, 0],
                    [0, 2.588],
                    [4.072, 0.719],
                    [0, 4.169],
                    [-5.366, 0],
                  ],
                  v: [
                    [-9.726, 5.031],
                    [-5.941, 4.169],
                    [0.479, 8.96],
                    [5.845, 5.127],
                    [-0.383, 1.342],
                    [-8.768, -5.27],
                    [0.288, -12.17],
                    [9.391, -5.893],
                    [5.654, -5.031],
                    [0.288, -8.959],
                    [-4.935, -5.414],
                    [0.863, -1.964],
                    [9.726, 4.887],
                    [0.431, 12.17],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [169.167, 45.388], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 29',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 29,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.743, 0],
                    [0, 5.175],
                    [4.696, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.696, 0],
                    [0, -5.126],
                    [-4.743, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.096, 3.642],
                    [7.954, -5.126],
                    [-0.096, -13.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.995],
                    [6.517, 0],
                    [1.965, 2.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.964, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.594, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.864],
                    [-8.193, -12.793],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.092],
                    [-8.145, 2.731],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.864],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [143.965, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 30',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 30,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.743, 0],
                    [0, 5.175],
                    [4.696, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.696, 0],
                    [0, -5.126],
                    [-4.743, 0],
                  ],
                  v: [
                    [-8.145, -5.126],
                    [-0.096, 3.642],
                    [7.954, -5.126],
                    [-0.096, -13.798],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.641, 0],
                    [0, -6.995],
                    [6.517, 0],
                    [1.965, 2.731],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.964, -2.779],
                    [6.517, 0],
                    [0, 7.091],
                    [-3.594, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-8.289, -16.864],
                    [-8.193, -12.793],
                    [0.431, -17.249],
                    [11.882, -5.126],
                    [0.431, 7.092],
                    [-8.145, 2.731],
                    [-8.145, 17.249],
                    [-11.882, 17.249],
                    [-11.882, -16.864],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [114.211, 50.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 31',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 31,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.63, 1.868],
                    [-0.12, -13.319],
                    [-5.87, 1.868],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.276, -17.967],
                    [2.229, -17.967],
                    [16.026, 17.967],
                    [11.715, 17.967],
                    [7.02, 5.558],
                    [-7.259, 5.558],
                    [-11.954, 17.967],
                    [-16.026, 17.967],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [81.702, 39.208], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 32',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 32,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.126],
                    [-4.695, 0],
                    [0, 5.175],
                    [4.792, 0],
                  ],
                  o: [
                    [0, 5.175],
                    [4.792, 0],
                    [0, -5.126],
                    [-4.695, 0],
                  ],
                  v: [
                    [-7.953, 5.941],
                    [0.096, 14.709],
                    [8.146, 5.941],
                    [0.096, -2.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [3.545, 0],
                    [0, 7.091],
                    [-6.66, 0],
                    [-1.965, -2.54],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.964, 2.636],
                    [-6.66, 0],
                    [0, -6.995],
                    [3.497, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.289, 17.775],
                    [8.193, 13.942],
                    [-0.287, 18.159],
                    [-11.883, 5.941],
                    [-0.287, -6.181],
                    [8.146, -2.06],
                    [8.146, -18.159],
                    [11.883, -18.159],
                    [11.883, 17.775],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [49.193, 39.4], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 33',
          np: 6,
          cix: 2,
          bm: 0,
          ix: 33,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'SDK2..png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 3,
              s: [540, 540, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [540, 525, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [540, 555, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 90, s: [540, 540, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [360, 360, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100],
            },
            { t: 3, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
};

export default dappsLottie;
