import React from 'react';
import { Box } from '@chakra-ui/react';
import I18n from '../I18n';
import CtaBottom from '../ctaBottom';
import SubscribeEmail from '../ctaBottom/subscribeEmail';
import gtagEvent from '../../utils/gtag';

const CTA_DATA = [
  {
    bg: '#00ADFF',
    title: <I18n id="blocto.cta.integratedWith.title" />,
    content: <I18n id="blocto.cta.integratedWith.content" />,
    buttonText: <I18n id="blocto.cta.integratedWith.button" />,
    onClick: () => {
      const link = 'https://docs.blocto.app/blocto-sdk/overview';
      gtagEvent('bloctoweb_click_sdkdoc', {
        location: 'integration_block',
        link_url: link,
        link_domain: new URL(link).host,
      });
      window.open(link, '_blank');
    },
  },
  {
    bg: 'boGlobalColor.aqua.500',
    title: <I18n id="blocto.cta.ecosystem.title" />,
    content: <I18n id="blocto.cta.ecosystem.content" />,
    buttonText: <I18n id="blocto.cta.ecosystem.button" />,
    onClick: () => {
      gtagEvent('bloctoweb_click_ecosystem', {
        location: 'integration_block',
      });
      window.location.href = '/web3-ecosystem';
    },
  },
];

const CTA = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '40px', md: '80px', lg: 0 }}
    pb={{ base: '80px', lg: '160px' }}
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <CtaBottom data={CTA_DATA}>
        <SubscribeEmail />
      </CtaBottom>
    </Box>
  </Box>
);

export default CTA;
