import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import Banner from '../components/sdk/banner';
import Features from '../components/sdk/features';
import Intro from '../components/sdk/intro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/sdk/cta';
import NewFeature from '../components/sdk/newFeature';
import Spotlight from '../components/sdk/spotlight';
import { AppContext } from '../context/index';

// eslint-disable-next-line no-unused-vars
const SDKPage = ({ intl }) => (
  <AppContext.Consumer>
    {() => (
      <Layout>
        <SEO
          title="Blocto SDK Integration | Crypto Wallet Integration | Blocto SDK"
          description="Blocto SDK simplifies crypto wallet integration, supporting mobile integration, Unity games and convenient wallet aggregator for a seamless Web3 experience"
          path="sdk"
        />
        <Banner />
        <Features />
        <Intro />
        <NewFeature />
        <Spotlight />
        <CTA />
      </Layout>
    )}
  </AppContext.Consumer>
);

SDKPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SDKPage);
