import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, Tag, Flex } from '@chakra-ui/react';
import I18n from './I18n';
import CustomLink from './customLink';
import RightArrowBlue from '../../static/new_images/rightArrow-blue.svg';

const WIDTHS = {
  lg: '470px',
  sm: '307px',
};

const IMG_WIDTHS = {
  lg: { base: '80px', lg: '160px' },
  sm: '80px',
};

const TITLE_FONT_SIZES = {
  lg: { base: '24px', md: '39px' },
  sm: { base: '20px', md: '25px' },
};

const CONTENT_FONT_SIZES = {
  lg: { base: 'size.body.3', md: 'size.body.2' },
  sm: { base: 'size.body.3', md: 'size.body.2' },
};

const BG_COLORS = {
  blue: '#32BEFF',
  darkBlue: 'icon.text',
  gray: 'border.tertiary',
};

const FONT_COLORS = {
  blue: 'font.inverse',
  darkBlue: 'font.inverse',
  gray: 'icon.text',
};

const Card = ({ size, color, imgSrc, title, content, tags, to, isExternal, gtag, ...rest }) => (
  <Flex
    flexDirection="column"
    width={WIDTHS[size]}
    bg={BG_COLORS[color]}
    borderRadius="30px"
    p={{ base: 'space.xl', md: 'space.3xl' }}
    {...rest}
  >
    <Image src={imgSrc} width={IMG_WIDTHS[size]} height={IMG_WIDTHS[size]} />
    <Text
      fontSize={TITLE_FONT_SIZES[size]}
      color={FONT_COLORS[color]}
      mt={{ base: size === 'lg' ? 'space.s' : 'space.xl', lg: 'space.xl' }}
      mb={{ base: tags.length ? '0' : 'space.s', md: tags.length ? 'space.2xs' : 'space.xl' }}
      lineHeight="120%"
      maxWidth={{ base: '410px' }}
      fontWeight={color === 'gray' ? 'weight.m' : 'weight.s'}
    >
      {title}
    </Text>
    <Text
      fontSize={CONTENT_FONT_SIZES[size]}
      color={FONT_COLORS[color]}
      lineHeight={{ base: '150%', md: '130%' }}
      mb={tags.length ? 'space.xl' : '0'}
    >
      {content}
    </Text>
    <Flex flex="1" flexDirection="column" justifyContent="space-between">
      {!!tags.length && (
        <Flex flexWrap="wrap" gap="space.4xs" mb={{ base: 'space.s', md: 'space.xl' }}>
          {tags.map((tag) => (
            <Tag size="md" variant="gray" px="space.s" fontSize="size.body.3">
              {tag}
            </Tag>
          ))}
        </Flex>
      )}
      {to && (
        <Flex justifyContent="flex-end">
          <CustomLink
            to={to}
            isExternal={isExternal}
            gtag={gtag}
            noHoverStyle
            display="inline-flex"
            p="space.2xs"
            pr="space.m"
            alignItems="center"
          >
            <Image src={RightArrowBlue} width="28px" height="28px" mr="space.s" />
            <I18n id="blocto.common.more" />
          </CustomLink>
        </Flex>
      )}
    </Flex>
  </Flex>
);

Card.propTypes = {
  size: PropTypes.oneOf(['lg', 'sm']),
  color: PropTypes.oneOf(['blue', 'darkBlue', 'gray']),
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  gtag: PropTypes.shape({ event: PropTypes.string, properties: PropTypes.object }),
};

Card.defaultProps = {
  size: 'lg',
  color: 'gray',
  tags: [],
  to: '',
  isExternal: false,
  gtag: { event: '' },
};

export default Card;
