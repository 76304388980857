import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import Lottie from 'react-lottie';
import I18n from '../../I18n';
import chainsLottie from './lottie/chains';
import dappsLottie from './lottie/dapps';
import unityLottie from './lottie/unity';
import adaptersLottie from './lottie/adapters';

const INTRO = [
  {
    lottieInfo: {
      loop: true,
      autoplay: true,
      animationData: chainsLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    title: <I18n id="blocto.sdk.intro.section1.title" />,
    content: <I18n id="blocto.sdk.intro.section1.content" />,
  },
  {
    lottieInfo: {
      loop: true,
      autoplay: true,
      animationData: dappsLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    title: <I18n id="blocto.sdk.intro.section2.title" />,
    content: <I18n id="blocto.sdk.intro.section2.content" />,
  },
  {
    lottieInfo: {
      loop: true,
      autoplay: true,
      animationData: unityLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    title: <I18n id="blocto.sdk.intro.section3.title" />,
    content: <I18n id="blocto.sdk.intro.section3.content" />,
  },
  {
    lottieInfo: {
      loop: true,
      autoplay: true,
      animationData: adaptersLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    title: <I18n id="blocto.sdk.intro.section4.title" />,
    content: <I18n id="blocto.sdk.intro.section4.content" />,
  },
];

const Intro = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '80px', lg: '160px' }}
    pb={{ base: '80px', md: '160px' }}
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Box textAlign="center" maxWidth="626px" margin="0 auto 80px">
        <Text
          color="icon.text"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
          maxWidth="626px"
          margin="0 auto"
        >
          <I18n id="blocto.sdk.intro.title" />
        </Text>
        <Text
          color="icon.text"
          fontSize={{ base: 'size.body.2', md: '20px' }}
          lineHeight={{ base: '130%', md: '140%' }}
          mt={{ base: 'space.m', md: 'space.xl' }}
        >
          <I18n id="blocto.sdk.intro.content" />
        </Text>
      </Box>

      <Box>
        {INTRO.map((intro, i) => (
          <Flex
            key={intro.title}
            flexDirection={{ base: 'column', lg: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            px={{ base: '0', lg: 'space.3xl' }}
            py="space.3xl"
            mb="space.xl"
            _last={{ mb: '0' }}
          >
            <Box
              order={{ lg: i % 2 ? '1' : '0' }}
              width={{ base: '240px', md: '480px' }}
              height={{ base: '240px', md: '480px' }}
              mb={{ base: 'space.xl', lg: '0' }}
            >
              <Lottie options={intro.lottieInfo} height="100%" width="100%" />
            </Box>

            <Box width={{ md: '480px', lg: 'auto' }} maxWidth={{ md: '480px' }}>
              <Text
                color="icon.text"
                fontSize={{ base: '20px', md: '39px' }}
                fontWeight={{ base: 'weight.m', md: 'weight.s' }}
                lineHeight="120%"
                mb={{ base: 'space.s', md: 'space.xl' }}
                maxWidth={{ md: '360px' }}
              >
                {intro.title}
              </Text>
              <Text
                color="icon.text"
                fontSize={{ base: 'size.body.3', md: 'size.body.2' }}
                lineHeight={{ base: '150%', md: '130%' }}
                maxWidth={{ md: '400px' }}
              >
                {intro.content}
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  </Box>
);

export default Intro;
