/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Flex, Image, Text, Box, useBreakpointValue } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import I18n from '../I18n';
import 'swiper/css';
import 'swiper/css/pagination';
import Button from '../button';
import PageArrow from '../svgIcon/pageArrow';
import CustomLink from '../customLink';
import gtagEvent from '../../utils/gtag';
import spotlight1Img from '../../../static/new_images/sdk/spotlight-1.png';
import spotlight1MdImg from '../../../static/new_images/sdk/spotlight-1-md.png';
import spotlight1BaseImg from '../../../static/new_images/sdk/spotlight-1-base.png';
import spotlight2Img from '../../../static/new_images/sdk/spotlight-2.png';
import spotlight2MdImg from '../../../static/new_images/sdk/spotlight-2-md.png';
import spotlight2BaseImg from '../../../static/new_images/sdk/spotlight-2-base.png';
import spotlight3Img from '../../../static/new_images/sdk/spotlight-3.png';
import spotlight3MdImg from '../../../static/new_images/sdk/spotlight-3-md.png';
import spotlight3BaseImg from '../../../static/new_images/sdk/spotlight-3-base.png';
import spotlight4Img from '../../../static/new_images/sdk/spotlight-4.png';
import spotlight4MdImg from '../../../static/new_images/sdk/spotlight-4-md.png';
import spotlight4BaseImg from '../../../static/new_images/sdk/spotlight-4-base.png';

const FeatureCardInfo = [
  {
    icon: spotlight1Img,
    iconTablet: spotlight1MdImg,
    iconMobile: spotlight1BaseImg,
    title: '3 Key Benefits of ERC-4337: Why Developers Should Care',
    content:
      'Are you tired of sifting through countless Ethereum proposals and standards to find the one that actually makes a difference? Look no further than Ethereum standard ERC-4337.',
    date: 'May 29, 2023',
    link: 'https://blocto.io/crypto-blog/blockchain-education/3-key-benefits-of-erc-4337-why-developers-should-care',
  },
  {
    icon: spotlight2Img,
    iconTablet: spotlight2MdImg,
    iconMobile: spotlight2BaseImg,
    title: 'How SDK Can Fast-Track Your Web3 Project',
    content:
      'Learn the benefits of integrating with a software development kit (SDK) and get started with Blocto SDK!',
    date: 'Mar 3, 2023',
    link: 'https://blocto.io/crypto-blog/blockchain-education/web3-sdk',
  },
  {
    icon: spotlight3Img,
    iconTablet: spotlight3MdImg,
    iconMobile: spotlight3BaseImg,
    title: 'Unlocking Wallet Potential: Exploring EVM Account Abstraction (EIP-4337)',
    content:
      'ERC-4337 and account abstraction will unlock the full potential of smart contract wallet, and Blocto has been an account abstraction wallet since 2019.',
    date: 'Feb 24, 2023',
    link: 'https://blocto.io/crypto-blog/blockchain-education/evm-account-abstraction',
  },
  {
    icon: spotlight4Img,
    iconTablet: spotlight4MdImg,
    iconMobile: spotlight4BaseImg,
    title: 'Why Blocto’s web team switched our JS SDK to Monorepo',
    content:
      'Explores the advantages of a Monorepo architecture, and outlines our journey from Multi-Repo to Monorepo.',
    date: 'May 22, 2023',
    link: 'https://medium.portto.com/why-bloctos-web-team-switched-our-js-sdk-to-monorepo-435f6fc72722',
  },
];

const Spotlight = () => {
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const size = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg' });
  const iconName = useBreakpointValue({ base: 'iconMobile', md: 'iconTablet', lg: 'icon' });

  const prev = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'feature_article_block',
    });
    swiper.slideTo(0);
  };
  const nexto = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'feature_article_block',
    });
    swiper.slideTo(3);
  };

  const swiperProps =
    size === 'lg'
      ? { slidesPerView: 3 }
      : {
          width: '276',
          breakpoints: {
            768: {
              width: 363,
            },
          },
        };

  return (
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '80px', lg: '120px' }}
      pb={{ base: '40px', md: '80px', lg: '120px' }}
    >
      <Box w="100%" maxW="1200px" m="0 auto">
        <Text
          color="icon.text"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
          mb={{ base: '16px', md: '40px', lg: '56px' }}
          maxWidth={{ base: '328px', lg: '100%' }}
        >
          <I18n id="blocto.cta.spotlight" />
        </Text>

        <Box w="100%">
          <Swiper
            spaceBetween={size === 'base' ? 12 : 24}
            style={{
              paddingBottom: size === 'lg' ? '24px' : '20px',
              marginBottom: size === 'lg' ? '0' : '-20px',
            }}
            onSwiper={(s) => {
              setSwiper(s);
            }}
            onSlideChange={(e) => {
              setSwiperIndex(e.realIndex);
            }}
            {...swiperProps}
          >
            {FeatureCardInfo.map((item) => (
              <SwiperSlide key={item.content}>
                <CustomLink
                  noHoverStyle
                  to={item.link}
                  isExternal
                  gtag={{
                    event: 'bloctoweb_click_link',
                    properties: {
                      location: 'feature_article_block',
                      topic_title: item.title,
                      link_url: item.link,
                      link_domain: new URL(item.link).host,
                    },
                  }}
                  display="flex"
                  flexDirection="column"
                  bg="background.primary"
                  borderRadius="32px"
                  boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.05)"
                  overflow="hidden"
                  h={{ base: '392px', md: '524px' }}
                >
                  <Image borderRadius="0 0 44px 44px" w="100%" src={item[iconName]} />
                  <Flex
                    flex="1"
                    flexDirection="column"
                    justifyContent="space-between"
                    px="space.m"
                    py="space.xl"
                  >
                    <Box pt="space.4xs" px="space.m">
                      <Text
                        display="-webkit-box"
                        color="icon.text"
                        fontSize={{ base: 'size.body.2', md: '25px' }}
                        fontWeight="weight.m"
                        lineHeight="120%"
                        mb="space.m"
                        overflow="hidden"
                        sx={{
                          '-webkit-line-clamp': '2',
                          '-webkit-box-orient': 'vertical',
                        }}
                      >
                        {item.title}
                      </Text>
                      <Text
                        display="-webkit-box"
                        color="icon.text"
                        fontSize={{ base: 'size.body.3', md: 'size.body.2' }}
                        lineHeight={{ base: '150%', md: '130%' }}
                        opacity="0.5"
                        overflow="hidden"
                        sx={{
                          '-webkit-line-clamp': '3',
                          '-webkit-box-orient': 'vertical',
                        }}
                      >
                        {item.content}
                      </Text>
                    </Box>

                    <Flex align="center" justify="space-between" pl="space.m">
                      <Text color="icon.text" fontSize="13px" lineHeight="150%">
                        {item.date}
                      </Text>
                      <Button
                        variant="plain"
                        withArrowIcon
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight="130%"
                      >
                        <I18n id="blocto.common.more" />
                      </Button>
                    </Flex>
                  </Flex>
                </CustomLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Flex
          w="100%"
          justify="flex-end"
          p={{ base: '0 0 40px 0', sm: '0 0 64px 64px', lg: '0' }}
          display={{ base: 'none', lg: 'flex' }}
          maxW={{ md: '1200px' }}
          m={{ md: '0 auto' }}
        >
          <Button
            mode="icon"
            variant="icon"
            mr="space.m"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={prev}
            disabled={swiperIndex === 0}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 0 ? '#BFC2C6' : undefined} transform="scaleX(-1)" />
          </Button>
          <Button
            mode="icon"
            variant="icon"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={nexto}
            disabled={swiperIndex === 2}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 2 ? '#BFC2C6' : undefined} />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Spotlight;
