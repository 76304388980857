import React from 'react';
import { Flex, Box, Text, Tag, Image } from '@chakra-ui/react';
import I18n from '../I18n';
import { AnimationButton } from '../button';
import gtagEvent from '../../utils/gtag';
import editorImg from '../../../static/new_images/sdk/editor.png';

const Features = () => (
  <>
    <Box
      height={{ base: '20px', md: '40px' }}
      bg="boGlobalColor.iron.100"
      borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
    />
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: 'space.l', md: 'space.xl', lg: '80px' }}
      pb={{ base: '40px', md: '80px', lg: '80px' }}
      bg="boGlobalColor.iron.100"
      borderRadius={{ base: '0 0 30px 30px', md: '0 0 60px 60px' }}
    >
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        maxW="1200px"
        m="0 auto"
        position="relative"
      >
        <Image
          src={editorImg}
          alt="Blocto SDK example"
          width={{ base: '100%', md: '640px', lg: '545px' }}
          order={{ base: '1', lg: '0' }}
        />

        <Box width={{ base: '100%', lg: '474px' }} mb={{ base: '40px', md: '80px', lg: '0' }}>
          <Tag variant="blue">
            <I18n id="blocto.sdk.features.tag" />
          </Tag>
          <Text
            color="icon.text"
            fontSize={{ base: '32px', md: '49px' }}
            fontWeight="weight.m"
            lineHeight="120%"
            mt="space.xl"
          >
            <I18n id="blocto.sdk.features.title" />
          </Text>
          <Text
            color="icon.text"
            fontSize={{ base: '16px', md: '20px' }}
            lineHeight={{ base: '130%', md: '140%' }}
            mt="space.s"
            mb="space.xl"
          >
            <I18n id="blocto.sdk.features.content" />
          </Text>
          <AnimationButton
            variant="primary"
            onClick={() => {
              const link = 'https://docs.blocto.app/blocto-sdk/overview';
              gtagEvent('bloctoweb_click_sdkdoc', {
                location: 'simple_introduction_sdk_block',
                link_url: link,
                link_domain: new URL(link).host,
              });
              window.open(link, '_blank');
            }}
          >
            <I18n id="blocto.sdk.features.button" />
          </AnimationButton>
        </Box>
      </Flex>
    </Box>
  </>
);

export default Features;
