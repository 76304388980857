import image1 from '../../../../../static/lottie/sdk/chains-1.png';
import image2 from '../../../../../static/lottie/sdk/chains-2.png';
import image3 from '../../../../../static/lottie/sdk/chains-3.png';
import image4 from '../../../../../static/lottie/sdk/chains-4.png';

const chainsLottie = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 90,
  w: 1080,
  h: 1080,
  nm: 'Feature1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 1080, h: 1080, u: '', p: image1, e: 0 },
    { id: 'image_1', w: 1080, h: 1080, u: '', p: image2, e: 0 },
    { id: 'image_2', w: 1080, h: 1080, u: '', p: image3, e: 0 },
    { id: 'image_3', w: 514, h: 514, u: '', p: image4, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Circle1.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [60] },
            { t: 89, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Circle2.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [50] },
            { t: 89, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Circle3.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [30] },
            { t: 89, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'SDK',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [539.805, 539.805, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [539.805, 524.805, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [539.805, 551.805, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 90, s: [539.805, 539.805, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [256.805, 256.805, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100],
            },
            { t: 3, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
};

export default chainsLottie;
