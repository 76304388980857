import React from 'react';
import { Text, Box, Tag, Grid, GridItem } from '@chakra-ui/react';
import I18n from '../I18n';
import Card from '../card';
import jsImg from '../../../static/new_images/sdk/js-sdk.png';
import iosImg from '../../../static/new_images/sdk/ios-sdk.png';
import androidImg from '../../../static/new_images/sdk/android-sdk.png';
import unityImg from '../../../static/new_images/sdk/unity-sdk.png';

const CARD_INFO = [
  {
    img: jsImg,
    title: 'Javascript SDK',
    content: <I18n id="blocto.sdk.newFeature.supportChains" />,
    chains: [
      'Ethereum',
      'Polygon',
      'Flow',
      'Aptos',
      'BNB Chain',
      'Avalanche',
      'Optimism',
      'Arbitrum',
    ],
    to: 'https://docs.blocto.app/blocto-sdk/javascript-sdk',
    isExternal: true,
  },
  {
    img: iosImg,
    title: 'iOS SDK',
    content: <I18n id="blocto.sdk.newFeature.supportChains" />,
    chains: ['Ethereum', 'Polygon', 'Flow', 'BNB Chain', 'Avalanche', 'Optimism', 'Arbitrum'],
    to: 'https://docs.blocto.app/blocto-sdk/ios-sdk',
    isExternal: true,
  },
  {
    img: androidImg,
    title: 'Android SDK',
    content: <I18n id="blocto.sdk.newFeature.supportChains" />,
    chains: ['Ethereum', 'Polygon', 'Flow', 'BNB Chain', 'Avalanche', 'Optimism', 'Arbitrum'],
    to: 'https://docs.blocto.app/blocto-sdk/android-sdk',
    isExternal: true,
  },
  {
    img: unityImg,
    title: 'Unity SDK',
    content: <I18n id="blocto.sdk.newFeature.supportChains" />,
    chains: [
      'Ethereum',
      'Polygon',
      'Flow',
      'Aptos',
      'BNB Chain',
      'Avalanche',
      'Optimism',
      'Arbitrum',
    ],
    to: 'https://docs.blocto.app/blocto-sdk/unity-sdk',
    isExternal: true,
  },
];

const NewFeature = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    py={{ base: '40px', md: '80px' }}
    bg="border.highlight"
    borderRadius={{ base: '30px', md: '60px' }}
  >
    <Box width="100%" maxWidth="1200px" margin="0 auto">
      <Tag variant="white">
        <I18n id="blocto.sdk.newFeatures.tag" />
      </Tag>
      <Text
        color="font.inverse"
        fontSize={{ base: '32px', md: '49px' }}
        fontWeight="weight.m"
        lineHeight="120%"
        maxWidth="626px"
        mt="space.xl"
        mb={{ base: '40px', md: '80px', lg: '56px' }}
      >
        <I18n id="blocto.sdk.newFeatures.title" />
      </Text>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        gap={{ base: 'space.s', md: 'space.xl', lg: 'space.m' }}
      >
        {CARD_INFO.map((card) => (
          <GridItem width="100%" height="100%">
            <Card
              key={card.title}
              size="sm"
              imgSrc={card.img}
              title={card.title}
              content={card.content}
              tags={card.chains}
              to={card.to}
              isExternal={card.isExternal}
              gtag={{
                event: 'bloctoweb_click_link',
                properties: {
                  location: 'sdk_block',
                  topic_title: card.title,
                  link_url: card.to,
                  link_domain: new URL(card.to).host,
                },
              }}
              width="100%"
              height="100%"
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  </Box>
);

export default NewFeature;
